<template>
  <main class="w-full md:w-11/12 mx-auto px-4 md:px-0">
    <h4 class="no-printable capitalize hidden md:block md:mt-3 md:text-sm md:text-left text-xs text-headerText">
      Payroll /
      <span class="cursor-pointer" @click="$router.go(-1)">View Salary</span> / Payslips /
      {{ months[payrollForMonth && payrollForMonth.month] }} {{ payrollForMonth && payrollForMonth.year }} /
      {{ employee && employee.profile.firstName }}
    </h4>

    <div class="no-printable w-full flex items-center justify-between md:justify-start mt-2 mb-4">
      <easiButton @click="$router.go(-1)" variant="text" color="dark-800" size="small" class="-ml-2">
        <i class="text-base fas fa-angle-left mr-3 cursor-pointer"></i>
        <span class="text-base">Back</span>
      </easiButton>

      <h4
        class="no-printable block w-44 sm:w-56 md:hidden md:mt-3 md:text-sm md:text-left text-xs text-headerText truncate whitespace-nowrap"
      >
        Payroll /
        <span class="cursor-pointer" @click="$router.go(-1)">View Salary</span>
        / Payslips / {{ months[payrollForMonth && payrollForMonth.month] }}
        {{ payrollForMonth && payrollForMonth.year }} /
        {{ employee && employee.profile.firstName }}
      </h4>
    </div>

    <div class="no-printable w-full flex flex-col md:flex-row items-center justify-between gap-6">
      <div class="md:bg-white md:border-2 md:border-outlineGray p-2 rounded-2xl flex space-x-3">
        <easiButton
          variant="outlined"
          size="small"
          rounded="lg"
          border="2"
          v-for="action in actions"
          :key="action.title"
          :loading="action.loading"
          @click="action.action"
        >
          <span class="flex items-center gap-2 p-1 md:p-0">
            <span class="hidden md:block"> {{ action.title }}</span>

            <i :class="action.icon" />
          </span>
        </easiButton>
      </div>

      <div class="flex items-center space-x-5">
        <div class="w-fit flex gap-2 items-center" :class="statusColor">
          <i class="fas fa-circle"></i>
          <span class="font-medium">
            {{ salaryDetails && salaryDetails.payslipStatus ? salaryDetails.payslipStatus.replace("_", " ") : "N/A" }}
          </span>
        </div>
        <easiButton @click="sendPayslip" :loading="loading" class="md:py-4">
          <span class=""> Send Payslip(s) </span>
        </easiButton>
      </div>
    </div>

    <div class="mt-8 px-4 md:px-14 py-8 rounded-lg md:rounded-xl bg-white border border-outlineGray shadow-2xl">
      <div class="flex items-center justify-between mb-4">
        <div
          class="overflow-hidden border border-indigo-100 w-24 h-24 rounded-full text-white flex items-center justify-center font-bold text-2xl"
        >
          <img
            v-if="companyData && companyData.company.logo"
            :src="companyData && companyData.company.logo"
            alt=""
            class="object-cover w-full h-full rounded-full"
          />
          <img v-else src="@/assets/img/c-logo.png" class="object-cover object-center w-full h-full" alt="" />
        </div>

        <div class="flex flex-col gap-1 md:gap-2 items-start justify-between">
          <h4 class="text-2xl md:text-4xl font-bold text-dark-800 md:ml-auto">Payslip</h4>
          <div class="bg-mint text-grey font-medium md:font-thin text-base md:text-md rounded-lg p-2">
            <div class="onPrintStyles">
              {{ months[payrollForMonth && payrollForMonth.month] }},
              {{ payrollForMonth && payrollForMonth.year }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="flex flex-col self-start justify-between gap-4">
        <div class="w-full flex items-start gap-4">
          <div class="flex items-start flex-col">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Title</span
            >
            <span
              class="font-bold text-2xl md:text-3xl text-dark-800 md:font-medium"
              >{{
                employee && employee.profile.title
                  ? employee.profile.title
                  : "N/A"
              }}</span
            >
          </div>
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >First Name</span
            >
            <span
              class="font-bold text-2xl md:text-3xl text-dark-800 md:font-medium capitalize"
              >{{
                employee && employee.profile.firstName
                  ? employee.profile.firstName
                  : "N/A"
              }}</span
            >
          </div>
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Last Name</span
            >
            <span
              class="font-bold text-2xl md:text-3xl text-dark-800 md:font-medium capitalize"
              >{{
                employee && employee.profile.lastName
                  ? employee.profile.lastName
                  : "N/A"
              }}</span
            >
          </div>
        </div>
        <div class="w-full flex flex-wrap items-start gap-6">
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Employee ID:</span
            >
            <span class="text-base md:text-lg text-dark-800 font-medium">{{
              employee && employee.job.userName ? employee.job.userName : "N/A"
            }}</span>
          </div>
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Job Title:</span
            >
            <span
              class="text-base md:text-lg text-dark-800 font-medium capitalize"
              >{{
                employee && employee.job.jobTitle
                  ? employee.job.jobTitle
                  : "N/A"
              }}</span
            >
          </div>
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Department:</span
            >
            <span class="text-base md:text-lg text-dark-800 font-medium">{{
              employee && employee.department && employee.department.name
                ? employee.department.name
                : "N/A"
            }}</span>
          </div>
          <div class="flex flex-col items-start">
            <span class="text-sm md:text-base font-medium text-dark-200"
              >Employment Type:</span
            >
            <span class="text-base md:text-lg text-dark-800 font-medium">{{
              formatEmploymentType(employee && employee.job.employmentType)
            }}</span>
          </div>

        
        </div>
      </div> -->

      <!--Start-->

      <div class="flex flex-col md:flex-row gap-4 md:items-center border border-newAsh bg-newAsh p-4 mt-8 rounded-xl">
        <div class="flex w-full flex-col gap-2 md:gap-0 self-start">
          <div class="w-full flex flex-col md:grid md:grid-cols-5 items-start gap-2">
            <div class="w-full flex md:flex-row flex-col items-start md:items-center col-span-5 gap-3">
              <div
                class="flex-none bg-white p-2 w-24 border-2 border-newAsh h-24 md:h-24 md:w-24 rounded-xl overflow-hidden"
              >
                <img
                  v-if="employee && employee.profile.pfp"
                  class="w-full h-full object-cover"
                  :src="employee && employee.profile.pfp"
                  alt=""
                />
                <div
                  v-else
                  class="bg-secondary rounded-xl text-white text-4xl uppercase md:text-3xl font-bold flex items-center justify-center w-full h-full"
                >
                  {{ getAvatar() }}
                </div>
              </div>

              <div class="flex bg-white rounded-xl px-3 py-5 w-full items-start flex-col gap-2">
                <span class="text-xs md:text-sm font-medium text-newLightgrey">Full Name</span>
                <span class="text-base md:text-base font-medium"
                  >{{ employee && employee.profile && employee.profile.title }}.
                  {{ employee && employee.profile && employee.profile.firstName }}
                  {{ employee && employee.profile && employee.profile.lastName }}</span
                >
              </div>
            </div>

            <div class="flex flex-col bg-white col-span-1 rounded-xl p-3 w-full gap-2 items-start">
              <span class="text-xs font-medium text-newLightgrey">EMPLOYEE ID:</span>
              <span class="text-base capitalize md:text-base font-medium">{{
                employee && employee.job && employee.job.userName
              }}</span>
            </div>
            <div class="flex flex-col bg-white col-span-1 rounded-xl p-3 w-full gap-2 items-start">
              <span class="text-xs md:text-sm font-medium text-newLightgrey">Job Title</span>
              <span class="text-base capitalize md:text-base font-medium">{{
                employee && employee.job && employee.job.jobTitle
              }}</span>
            </div>

            <div class="flex flex-col bg-white col-span-1 rounded-xl p-3 w-full gap-2 items-start">
              <span class="text-xs md:text-sm font-medium text-newLightgrey">Department:</span>
              <span class="text-base capitalize md:text-base font-medium">{{
                (employee && employee.job && employee.job.department) || "N/A"
              }}</span>
            </div>

            <div class="flex flex-col bg-white col-span-1 rounded-xl p-3 w-full gap-2 items-start">
              <span class="text-xs md:text-sm font-medium text-newLightgrey">Employment Type:</span>
              <span class="text-base capitalize md:text-sm font-medium">{{
                (employee && employee.job && employee.job.employmentType) || "N/A"
              }}</span>
            </div>

            <div class="flex flex-col bg-white col-span-1 rounded-xl p-3 w-full gap-2 items-start">
              <span class="text-xs md:text-sm font-medium text-newLightgrey">Work Days:</span>
              <span class="text-base capitalize md:text-sm font-medium">{{
                (employee && employee.job && employee.job.workDays) || "N/A"
              }}</span>
            </div>
            <div>
              <!-- <img
          src="@/assets/icons/edit.svg"
          class="cursor-pointer"
          alt=""
        /> -->
            </div>
            <hr class="bg-outlineGray col-span-5" />
          </div>
          <h3 class="text-dark-800 font-bold mt-4 mb-2">Payment details</h3>
          <div class="w-full grid grid-cols-1 md:grid-cols-5 items-start gap-2">
            <div class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2">
              <span class="text-xs md:text-sm text-newLightgrey">Bank Name:</span>
              <span class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium">{{
                (employee && employee.payment && employee.payment.bankName) || "N/A"
              }}</span>
            </div>
            <div class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2">
              <span class="text-xs md:text-sm text-newLightgrey">Account Number:</span>
              <span class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium">{{
                (employee && employee.payment && employee.payment.accountNumber) || "N/A"
              }}</span>
            </div>
            <div class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2">
              <span class="text-xs md:text-sm text-left text-newLightgrey">Payment reference:</span>
              <span class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium">{{
                (employee && employee.payment && employee.payment.reference) || "N/A"
              }}</span>
            </div>

            <div class="flex bg-white rounded-xl col-span-2 p-3 w-full items-start flex-col gap-2">
              <span class="text-xs md:text-sm text-left text-newLightgrey">Session ID:</span>
              <span class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium">{{
                (employee && employee.payment && employee.payment.sessionId) || "N/A"
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--end-->

      <div class="my-8 rounded-lg md:rounded-xl border-2 border-newLightGreen p-3 md:p-6">
        <div class="w-full" v-for="(item, i) in datas" :key="i + 'i'">
          <div v-if="item.data.length">
            <div class="mb-4 text-left text-2xl text-dark-800">
              {{ item.name }}
            </div>
            <table class="table-auto w-full overflow-y-auto">
              <thead>
                <tr>
                  <th
                    class="w-6/12 bg-newLimeGreen text-sm md:text-base py-3 px-3 md:px-6 text-left uppercase border border-outlineGray"
                  >
                    {{ item.header }}
                  </th>
                  <th
                    class="w-6/12 bg-newDirtyBrown text-sm md:text-base py-3 px-3 md:px-6 text-right uppercase border border-outlineGray"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody class="font-medium">
                <tr v-for="(list, i) in item.data" :key="i">
                  <td
                    :class="
                      list.name.toLocaleLowerCase().includes('gross')
                        ? 'border-2 border-r-0  border-dark-600'
                        : 'border border-outlineGray'
                    "
                    class="text-xs font-medium md:text-base py-3 px-3 md:px-6 text-left uppercase"
                  >
                    {{ list.name }}
                  </td>
                  <td
                    :class="
                      list.name.toLocaleLowerCase().includes('gross')
                        ? 'border-2 border-l-0 border-dark-600 text-xl'
                        : 'border border-outlineGray text-xs md:text-base'
                    "
                    class="py-3 px-3 md:px-6 text-right"
                  >
                    {{ formatAmount(list.amount) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr class="my-6 border border-mint" />
          </div>
        </div>

        <div
          class="w-full bg-primary text-white p-3 md:p-4 rounded-full gap-2 flex items-center justify-center addPaddingOnPrint"
        >
          <div class="onPrintStyles flex items-center">
            <span class="uppercase text-lg font-medium mr-4">Net Pay:</span>
            <span class="text-2xl">{{ formatAmount(salaryDetails && salaryDetails.netAmount) }}</span>
          </div>
        </div>
      </div>

      <div class="text-sm text-dark-800 text-center mt-6">
        Generated and processed by Eazipay <br />
        <a href="https://myeazipay.com" target="_blank" class="text-info-light font-bold">www.myeazipay.com</a>
      </div>
    </div>

    <ShareToAdmin :input="payload" type="payslip" :isOpen="shareModal" @close="shareModal = false" />

    <easiSuccess v-if="payslipSuccess" @close="payslipSuccess = false">
      <template v-slot:message>
        <span>Payslip(s) sent successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import ShareToAdmin from "../../../views/admin/payroll/history/ShareToAdmin.vue";

import { computed, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const store = useDataStore();
const route = useRoute();
const toast = useToast();

const { formatAmount, downloadBase64, printPDF } = helperFunctions;
const { query, mutate } = store;

const shareModal = ref(false);
const loading = ref(false);
const payslipSuccess = ref(false);
const printLoading = ref(false);
const downloadLoading = ref(false);

const payrollForMonth = computed(() => store.getPayrollHistoryForMonth);

const payload = computed(() => {
  const id = route.query.id;
  return {
    employeeIds: [id],
    month: payrollForMonth.value && payrollForMonth.value.month,
    year: payrollForMonth.value && payrollForMonth.value.year,
    timeline: payrollForMonth.value && payrollForMonth.value.timeline ? payrollForMonth.value.timeline : "MONTHLY",
    biweekly: payrollForMonth.value && payrollForMonth.value.biweekly,
  };
});

const actions = computed(() => {
  return [
    {
      title: "Print",
      icon: "pi pi-print",
      loading: printLoading.value,
      action() {
        handlePrint();
      },
    },
    {
      title: "Share",
      icon: "pi pi-share-alt",
      action() {
        handleShare();
      },
    },
    {
      title: "Download",
      icon: "pi pi-download",
      loading: downloadLoading.value,
      action() {
        handleDownload();
      },
    },
  ];
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const companyData = computed(() => store.getCompanyDetails);

const employee = computed(() => {
  if (payrollForMonth.value) {
    const id = route.query.id;

    const arr = Object.values(payrollForMonth.value.employeeDetails);
    const emp = arr.find((el) => el.employeeId == id);

    console.log("EMPLOPYEE", emp, arr);
    return emp;
  }
});

const getAvatar = () => {
  let name = "";

  employee
    ? (name = `${employee.value && employee.value.profile.firstName && employee.value.profile.firstName.charAt(0)}${
        employee.value && employee.value.profile.lastName && employee.value.profile.lastName.charAt(0)
      }`)
    : null;

  return name;
};

const salaryDetails = computed(() => {
  const id = route.query.id;
  return payrollForMonth.value.salaryRecords.find((el) => el.employeeId == id);
});

console.log("salaryDetails", salaryDetails.value);

const benefitDetails = computed(() => {
  const id = route.query.id;
  return payrollForMonth.value.statutoryRecords.filter((el) => el.employeeId == id);
});

const bonusDetails = computed(() => {
  const id = route.query.id;
  return payrollForMonth.value.bonusOrDeductionRecords
    .filter((el) => el.employeeId == id)
    .filter((el) => el.type == "Bonus");
});

const deductionDetails = computed(() => {
  const id = route.query.id;
  return payrollForMonth.value.bonusOrDeductionRecords
    .filter((el) => el.employeeId == id)
    .filter((el) => el.type == "Deduction");
});

console.log("SALARY", salaryDetails.value);

const datas = computed(() => {
  const arr = [];

  ///Salary Break Down
  const salaryBreakdown = {
    name: "Salary Breakdown",
    header: "Earnings",
    data: [],
  };

  const grossAmount = salaryDetails.value && salaryDetails.value.grossAmount;
  const allowance = salaryDetails.value && salaryDetails.value.allowanceDetails;

  if (allowance) {
    Object.keys(allowance).forEach(function (key) {
      salaryBreakdown.data.push({
        name: key,
        amount: allowance[key].percentage ? (allowance[key].percentage * grossAmount) / 100 : allowance[key].amount,
      });
    });
    salaryBreakdown.data.push({
      name: "Gross Pay",
      amount: grossAmount,
    });
    console.log("Salary Break Down", salaryBreakdown);
    arr.push(salaryBreakdown);
  }

  ///Statutory
  const statutory = {
    name: "Statutory Deductions",
    header: "Deductions",
    data: [],
  };

  const benefits = benefitDetails.value && benefitDetails.value;
  if (benefits) {
    benefits.forEach((el) => {
      statutory.data.push({
        name: el.name,
        amount: el.statutoryDetails.amount,
      });
    });

    arr.push(statutory);
  }

  ///Bonus

  const bonus = {
    name: "Bonuses",
    header: "Bonuses",
    data: [],
  };
  const bonuses = bonusDetails.value && bonusDetails.value;
  if (bonuses) {
    bonuses.forEach((el) => {
      bonus.data.push({ name: el.name, amount: el.amount });
    });

    arr.push(bonus);
  }

  ///Deduction
  const employeeDeduction = {
    name: "Other Deductions",
    header: "Deductions",
    data: [],
  };
  const deduction = deductionDetails.value && deductionDetails.value;
  if (deduction) {
    deduction.forEach((el) => {
      employeeDeduction.data.push({ name: el.name, amount: el.amount });
    });

    arr.push(employeeDeduction);
  }

  console.log("DATAS", arr);
  return arr;
});

const statusColor = computed(() => {
  const colors = {
    SENT: "text-success",
    NOT_SENT: "text-secondary",
  };

  return colors[salaryDetails.value && salaryDetails.value.payslipStatus];
});

const formatEmploymentType = (type) => {
  return type ? type.split("_").join(" ") : "N/A";
};

const handleShare = async () => {
  shareModal.value = true;
};
const handleDownload = async () => {
  try {
    const arg = { ...payload.value };
    arg.download = true;
    downloadLoading.value = true;
    const res = await mutate({
      endpoint: "SendPaySlipEmails",
      data: {
        input: arg,
      },
      service: "PAYROLL",
    });

    console.log(res);
    downloadLoading.value = false;

    if (res.data && res.data.base64s) {
      const { Base64Content, Filename } = res.data.base64s[0];
      downloadBase64(Base64Content, Filename);
    } else {
      toast.error(res.message);
      console.log("No Base64 Content");
    }
  } catch (error) {
    downloadLoading.value = false;
    console.log(error.message);
  }
};
const handlePrint = async () => {
  try {
    const arg = { ...payload.value };
    arg.download = true;
    printLoading.value = true;
    const res = await mutate({
      endpoint: "SendPaySlipEmails",
      data: {
        input: arg,
      },
      service: "PAYROLL",
    });

    console.log(res);
    printLoading.value = false;

    if (res.data && res.data.base64s) {
      const { Base64Content } = res.data.base64s[0];
      printPDF(Base64Content);
    } else {
      toast.error(res.message);
      console.log("No Base64 Content");
    }
  } catch (error) {
    printLoading.value = false;
    console.log(error.message);
  }
};

const sendPayslip = async () => {
  if (employee.value && employee.value.profile.email) {
    loading.value = true;

    try {
      const res = await mutate({
        endpoint: "SendPaySlipEmails",
        data: {
          input: payload.value,
        },
        service: "PAYROLL",
      });

      loading.value = false;

      if (res.success) {
        payslipSuccess.value = true;
        queryViewPayrollForMonthV3();
        setTimeout(() => {
          payslipSuccess.value = false;
        }, 3000);

        console.log(res);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    toast.error("User doesn't have an active email");
  }
};

const queryViewPayrollForMonthV3 = async () => {
  const payrollDate = route.params.date.split(",");
  const payload = {
    month: parseInt(payrollDate[0]),
    year: parseInt(payrollDate[1]),
    timeline: payrollDate[2],
    biweekly: payrollDate[3],
    sort: {},
    paging: {
      limit: 1000,
      skip: 0,
      search: "",
    },
    filter: [],
  };
  try {
    const res = await query({
      endpoint: "ViewPayrollForMonthV3",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "payrollForMonth",
    });

    console.log(res, "payrollForMonth");
    // loading.value = false;
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};
</script>

<style>
@media print {
  .no-printable {
    display: none !important;
  }
  .printable {
    display: block;
  }
}
</style>
